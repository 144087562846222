<template>
  <v-container pt-0>
    <v-toolbar flat>
      <v-btn text @click="close">
        <v-icon left>mdi-arrow-left</v-icon>
        <span>{{ $tc('back') }}</span>
      </v-btn>
      <v-spacer />
      <v-btn text :loading="exportingPoi" @click="exportPoi">
        <v-icon left>mdi-map-marker-down</v-icon>
        <span>{{ $tc('downloadShape') }}</span>
      </v-btn>
    </v-toolbar>
    <v-card :loading="pending" outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-container>
              <v-row>
                <v-text-field id="poi-name" label="Name" v-model="poiName" prepend-icon="mdi-map-marker-outline" :disabled="!canEdit" />
              </v-row>
              <v-row>
                <v-combobox id="poi-label" v-model="poiLabel" :items="availableLabels" label="Label" prepend-icon="mdi-label-outline" :disabled="isStudyArea" />
              </v-row>
              <v-row>
                <v-combobox id="poi-tags" v-model="poiTag" label="Tags" :items="availableTags" prepend-icon="mdi-tag-outline" multiple chips :disabled="isStudyArea" />
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card flat>
              <v-card-actions class="px-0 pt-0" v-show="canEdit">
                <v-spacer />
                <v-btn outlined :disabled="!coordsChanged" @click="revertShape">
                  <v-icon left>mdi-undo</v-icon>
                  <span>{{ $tc('revert') }}</span>
                </v-btn>
              </v-card-actions>
              <johannes ref="drawPoi" v-model="poi" height="400px" modifying @input="coordsChanged = true" @clear="coordsChanged = true" :read-only="!canEdit" />
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-show="canEdit">
        <v-spacer />
        <v-btn text color="primary" :loading="saving" @click="save">{{ $tc('save') }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="mt-5" outlined v-if="orders.length">
      <v-card-title class="text-h5">{{ $tc('includedInStudies') }}</v-card-title>
      <v-list>
        <template v-for="(order, index) in orders">
          <study-list-item :key="order.id" :study="order" />
          <v-divider :key="`${order.id}-${index}`" v-if="index + 1 < orders.length" />
        </template>
      </v-list>
    </v-card>
    <v-dialog v-model="showRerunDialog" max-width="600">
      <v-card>
        <v-card-title>{{ $tc('rerunStudies') }}</v-card-title>
        <v-divider />
        <v-card-text class="pt-4">
          <p>{{ $tc('rerunPrompt1') }}<br/>{{ $tc('rerunPrompt2') }}</p>
          <v-list dense>
            <template v-for="(order, index) in orders">
              <v-list-item :key="order.id">
                <v-list-item-avatar size="26">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon :color="getAvatarColor(order.product)" v-bind="attrs" v-on="on">{{ getAvatarIcon(order.product) }}</v-icon>
                    </template>
                    <span>{{ order.product | formatProduct }}</span>
                  </v-tooltip>
                </v-list-item-avatar>
                <v-list-item-content>{{ order.name }}</v-list-item-content>
                <v-list-item-action>
                  <v-checkbox v-model="ordersRerun[order.id]" />
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="`${order.id}-${index}`" v-if="index + 1 < orders.length" />
            </template>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn color="error" text @click="showRerunDialog = false">Cancel</v-btn>
          <v-spacer />
          <v-btn color="primary" depressed :loading="rerunningOrders" :disabled="!canRerunOrders" @click="rerunOrders">
            <v-icon left>mdi-refresh</v-icon>
            <span>Rerun</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import { mapState, mapGetters } from 'vuex';
import { feature } from '@turf/turf';
import alert from '@/mixins/alert';
import studyIcon from '@/mixins/studyIcon';
import Johannes from '@/components/johannes/Johannes';
import StudyListItem from '@/components/StudyListItem';

export default {
  mixins: [alert, studyIcon],

  components: {
    Johannes,
    StudyListItem
  },

  data() {
    return {
      ready: false,
      pending: false,
      poi: {},
      orders: [],
      organization: null,
      editing: false,
      saving: false,
      originalShape: null,
      coordsChanged: false,
      showRerunDialog: false,
      ordersRerun: {},
      rerunningOrders: false,
      exportingPoi: false
    };
  },

  async beforeRouteEnter(to, from, next) {
    next((vm) => vm.loadPoi(to.params.poi));
  },

  async beforeRouteUpdate(to, from, next) {
    this.poi = {};
    this.loadPoi(to.params.poi);
    next();
  },

  computed: {
    isStudyArea() {
      return get(this.poi, 'properties.purpose', 'poi') === 'studyarea';
    },

    canEdit() {
      return this.isAdmin || !this.isStudyArea;
    },

    poiName: {
      get() {
        return get(this.poi, 'properties.name', '');
      },

      set(value) {
        return set(this.poi, 'properties.name', value);
      }
    },

    poiLabel: {
      get() {
        return get(this.poi, 'properties.label', '');
      },

      set(value) {
        return set(this.poi, 'properties.label', value);
      }
    },

    poiTag: {
      get() {
        return get(this.poi, 'properties.tags', []);
      },

      set(value) {
        return set(this.poi, 'properties.tags', value);
      }
    },

    availableLabels() {
      return !isEmpty(this.organization) ? get(this.poiLabels, this.organization, []) : [];
    },

    availableTags() {
      return !isEmpty(this.organization) ? get(this.poiTags, this.organization, []) : [];
    },

    canRerunOrders: {
      cache: false,
      get() {
        return Object.values(this.ordersRerun).some((v) => v);
      }
    },

    ...mapState(['poiLabels', 'poiTags', 'pois']),
    ...mapGetters(['isAdmin'])
  },

  mounted() {
    this.ready = true;
  },

  watch: {
    ready() {
      if (!isEmpty(this.poi) && this.ready && !this.map) {
        this.initMap();
      }
    },

    poi(newValue, oldValue) {
      if (isEmpty(newValue.properties)) {
        newValue.properties = oldValue.properties;
      }
    },

    orders() {
      this.ordersRerun = {};
      this.orders.forEach(({ id }) => {
        this.$set(this.ordersRerun, id, true);
      });
    }
  },

  methods: {
    close() {
      this.$router.push({ name: 'pois' });
    },

    async loadPoi() {
      try {
        const { id } = this.$route.params;
        this.pending = true;
        const { data: poi } = await this.$services.geometry.read(id);
        this.poi = poi;
        Object.entries(this.pois).forEach(([org, pois]) => {
          const index = pois.findIndex((p) => p.id === this.poi.properties.id);
          if (~index) {
            this.organization = org;
          }
        });
        this.listOrders();
        this.saveOriginalShape();
      } catch (error) {
        this.alertError(error);
      } finally {
        this.pending = false;
      }
    },

    async listOrders() {
      try {
        const { id } = this.$route.params;
        this.pending = true;
        const { data: orders } = await this.$services.geometry.listOrders(id);
        this.orders = orders;
      } catch (error) {
        this.alertError(error);
      } finally {
        this.pending = false;
      }
    },

    async rerunOrders() {
      try {
        const { id } = this.$route.params;
        this.rerunningOrders = true;
        await Object.keys(pickBy(this.ordersRerun, (v) => v)).map((orderId) => {
          return this.$services.orders.rerun(orderId, { params: { pois: [id] } });
        });
      } catch (error) {
        this.alertError(error);
      } finally {
        this.rerunningOrders = false;
        this.showRerunDialog = false;
      }
    },

    async save() {
      try {
        this.saving = true;
        await this.$services.geometry.update(this.poi.properties.id, this.poi);
        this.alertSuccess('POI saved successfully');
        if (this.orders.length && this.coordsChanged) {
          this.showRerunDialog = true;
        }
        this.saveOriginalShape();
      } catch (error) {
        this.alertError(error);
      } finally {
        this.saving = false;
      }
    },

    saveOriginalShape() {
      this.originalShape = cloneDeep(this.poi.geometry);
      this.coordsChanged = false;
    },

    revertShape() {
      this.poi.geometry = this.originalShape;
      this.$refs.drawPoi.reload(this.poi);
      this.coordsChanged = false;
    },

    async exportPoi() {
      try {
        this.exportingPoi = true;
        const { geometry, properties } = this.poi;
        const filename = `${properties.name.replace(/\W/g, '')}.geojson`;
        const features = feature(geometry, properties);
        const blob = new Blob([JSON.stringify(features)], { type: 'application/json' });

        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, filename);
        } else {
          const downloadLink = document.createElement('a');
          downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
          downloadLink.setAttribute('download', filename);
          downloadLink.setAttribute('target', '_blank');
          document.body.append(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } catch (error) {
        this.alertError(error);
      } finally {
        this.exportingPoi = false;
      }
    }
  },

  filters: {
    formatDate(value) {
      return format(value, 'MMM d, yyyy');
    },

    formatProduct(value) {
      const products = {
        dla: 'Destination Location Analysis',
        tla: 'Target Location Analysis',
        ad: 'Activity Density',
        nwtm: 'Nationwide Trip Matrix'
      };
      return products[value.toLowerCase()];
    }
  }
};
</script>

<style lang="scss">
  .poi-map {
    width: 100%;
    height: 100%;
  }

  .mapboxgl-control-container {
    display: none;
  }
</style>
